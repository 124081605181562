// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const SET_ERROR = 'SET_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';

// User Actions
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_APPOINTMENTS = 'GET_USER_APPOINTMENTS';
export const GET_USER_DOCUMENTS = 'GET_USER_DOCUMENTS';
export const GET_USER_APPLICATIONS = 'GET_USER_APPLICATIONS';

// Admin Actions
export const GET_ALL_STUDENTS = 'GET_ALL_STUDENTS';
export const ADMIN_GET_ALL_STUDENTS_APPOINTMENTS =
  'ADMIN_GET_ALL_STUDENTS_APPOINTMENTS';
export const FILTER_APPOINTMENTS = 'FILTER_APPOINTMENTS';
export const FILTER_STUDENTS = 'FILTER_STUDENTS';
export const GET_ALL_APPOINTMENTS_DATA = 'GET_ALL_APPOINTMENTS_DATA';
export const GET_ALL_STUDENTS_DATA = 'GET_ALL_STUDENTS_DATA';
export const ADMIN_GET_ANALYTICS = 'ADMIN_GET_ANALYTICS';
export const FILTER_APPLICATIONS = 'FILTER_APPLICATIONS';

//Loading Actions
export const SET_LOADING_TRUE = 'SET_LOADING_TURE';
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';

//Notification Actions
export const SET_NEW_USER_NOTIFICATION = 'SET_NEW_USER_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
