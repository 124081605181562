import React, { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { BiSolidEdit } from 'react-icons/bi';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'components/modal/ApplicationModal';
import { RequiredDocsViewModal } from 'components/modal/RequiredDocsViewModal';
import { adminDeleteUserApplication } from 'redux/actions/ActionCreators/AdminActions';
import { UploadOfferLetterModal } from 'components/modal/UploadOfferLetterModal';
import { DocsViewModal } from 'components/modal/DocsViewModal';
import { useParams } from 'react-router-dom';

const AdminApplicationTable = ({
  applicationsData,
  page,
  renderStudentDeatils,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = useSelector((state) => state?.auth?.user?.access_token);

  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const [showMissingDocsModal, setShowMissingDocsModal] = useState(false);
  const [missingDocs, setMissingDocs] = useState(null);
  const [showOfferLetterModal, setShowOfferLetterModal] = useState(false);
  const [viewOfferLetterModal, setViewOfferLetterModal] = useState(false);
  const [offterLetter, setOfferLetter] = useState({
    url: '',
    doc_type: '',
    doc_name: '',
  });
  const [currentPage, setCurrentPage] = useState(1);

  // Set Cuurent Page
  useEffect(() => {
    if (page) {
      if (page == 1) {
        setCurrentPage(page);
      } else if (page > 1 && applicationsData.length > 1) {
        setCurrentPage(page);
      } else {
        setCurrentPage(page - 1);
      }
    }
  }, [page, applicationsData.length]);

  // Handler For Confirm Modal Close
  const handleConfirm = () => {
    setShowApplicationModal(false);
    setShowMissingDocsModal(false);
    setShowOfferLetterModal(false);
    setViewOfferLetterModal(false);
  };

  // Handle Delete Application
  const hanldeDeleteApplication = (applicationNo) => {
    dispatch(adminDeleteUserApplication(applicationNo, currentPage, token));
    if (id) {
      renderStudentDeatils(true);
    }
  };

  // Handler For Edit Application Btn (if application status be 1 then it show upload offer letter modal)
  const handleEditActions = (data) => {
    // When application's status is inprocess
    if (data?.status == 1) {
      setShowOfferLetterModal(true);
      setApplicationId(data?.id);
      // When application's status is Approved
    } else if (data?.status == 'Admission Offered') {
      setOfferLetter({
        url: data?.url,
        doc_type: data?.offer_letter?.split('.')[1],
        doc_name: data?.offer_letter,
      });
      if (offterLetter.doc_name) {
        setViewOfferLetterModal(true);
      }
    }
    // When application's status is pending
    else {
      setShowApplicationModal(true);
      setApplicationId(data?.id);
    }
  };
  // Handler For View Documents
  const handleViewDocuments = (data) => {
    // When application's status is Approved
    if (data?.status == 'Admission Offered') {
      setOfferLetter({
        url: data?.url,
        doc_type: data?.offer_letter?.split('.')[1],
        doc_name: data?.offer_letter,
      });
      if (offterLetter.doc_name) {
        setViewOfferLetterModal(true);
      }
    }
    // When application's status is inprocess
    else if (data?.status == 1) {
      toast.warning('Application is in process');
    }
    // When application's status is pending
    else {
      setShowMissingDocsModal(true);
      setMissingDocs({
        missing_docs: data?.missing_documents,
        required_docs: data?.required_doc,
      });
    }
  };

  return (
    <div className="flex flex-col px-2 w-full ">
      {/* Add/Edit Appication Modal */}
      {showApplicationModal && (
        <ApplicationModal
          onConfirm={handleConfirm}
          id={applicationId}
          page={currentPage}
        />
      )}
      {/* Missing Docs Modal */}
      {showMissingDocsModal && (
        <RequiredDocsViewModal
          onConfirm={handleConfirm}
          documents={missingDocs}
        />
      )}
      {/* Upload Offer Letter Modal */}
      {showOfferLetterModal && (
        <UploadOfferLetterModal
          onConfirm={handleConfirm}
          id={applicationId}
          page={currentPage}
          doc_name={'OfferLetter'}
          title={'Upload Offer Letter'}
        />
      )}
      {/* View Offer Letter Modal */}
      {viewOfferLetterModal && (
        <DocsViewModal onConfirm={handleConfirm} documentData={offterLetter} />
      )}
      <div className="sm:-mx-6 lg:-mx-8">
        <div className=" min-w-full py-2  lg:px-4">
          <div className="overflow-x-auto  custom-scroll-bar">
            <table className="min-w-full  text-left text-sm font-light">
              <thead className="whitespace-nowrap	bg-successLight font-medium ">
                <tr>
                  {!id && (
                    <th
                      scope="col"
                      className="px-4  py-4 text-success text-[14px] font-figtree font-bold leading-5"
                    >
                      User Id
                    </th>
                  )}
                  {!id && (
                    <th
                      scope="col"
                      className="px-4  py-4 text-success text-[14px] font-figtree font-bold leading-5"
                    >
                      Applicant Name
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-4 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    University Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-success  text-[14px] font-figtree font-bold leading-5"
                  >
                    Applied for Course
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-success   text-[14px] font-figtree font-bold leading-5"
                  >
                    Applied On
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-center text-success  text-[14px] font-figtree font-bold leading-5"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className="px-4 py-4 text-success text-center text-[14px] font-figtree font-bold leading-5"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {applicationsData.map((data, index) => (
                  <tr className="border-b border-[#E2E2EA]" key={index}>
                    {!id && (
                      <td className="whitespace-nowrap px-4 capitalize py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                        {data?.user_id}
                      </td>
                    )}
                    {!id && (
                      <td className="whitespace-nowrap px-4 capitalize py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                        {data?.userName}
                      </td>
                    )}
                    <td className="whitespace-nowrap px-4 capitalize py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data.applied_university}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 capitalize text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.course_name}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {moment(data?.created_at).format('MMMM DD, YYYY')}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.status == 1 && (
                        <p className="bg-successLight capitalize text-success text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          processing
                        </p>
                      )}
                      {data?.status == 0 && (
                        <p className="bg-red-100 text-red-600 capitalize text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Pending
                        </p>
                      )}
                      {data?.status == 2 && (
                        <p className="bg-pending text-white capitalize text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Rejetced
                        </p>
                      )}
                      {data?.status == 'Admission Offered' && (
                        <p className="bg-success text-white capitalize text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Admission Offered
                        </p>
                      )}
                    </td>

                    <td className="whitespace-nowrap flex justify-center items-center px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      <AiOutlineEye
                        size={20}
                        className=" text-primary cursor-pointer"
                        onClick={() => handleViewDocuments(data)}
                      />
                      <AiOutlineDelete
                        size={20}
                        className="text-gray mx-2 cursor-pointer"
                        onClick={() =>
                          hanldeDeleteApplication(data?.application_no)
                        }
                      />
                      {!id && (
                        <BiSolidEdit
                          size={20}
                          className="text-success flex   cursor-pointer"
                          onClick={() => handleEditActions(data)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminApplicationTable;
