import { Fragment, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';

import classes from './Modal.module.css';
import ProfileField from 'components/textfield/ProfileField';
import Spinner from 'components/spinner/Spinner';
import SelectTextField from 'components/textfield/SelectField';
import DetectClickOutside from 'modules/DetectClickOutside';
import { config } from 'helpers/config/Config';
import { useParams } from 'react-router-dom';
import moment from 'moment';

export const TheAddAppointmentFromStudentDetails = (props) => {
  const clickRef = useRef('');
  const params = useParams();
  const token = useSelector((state) => state.auth.user.access_token);
  const { userName, id, renderStudentDeatils, appointments } = props;
  const [appointment, setAppointment] = useState(null);

  const [loading, setLoading] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState({
    label: 'Select Status',
    value: '',
  });

  // Status Options List
  const statusOptions = [
    {
      value: 0,
      label: 'Pending',
    },
    {
      value: 1,
      label: 'Approved',
    },
  ];

  // Form Initial Values
  let initialValues = {
    status: appointment?.status,
    user: userName,
    name: appointment?.consultant_name,
    agenda: appointment?.agenda,
    date: appointment?.date,
    time: appointment?.time
      ? moment(appointment?.time, 'HH:mm:ss').format('hh:mm')
      : '',
  };

  // Error Schema
  const errorSchema = Yup.object().shape({
    agenda: Yup.string().required('Agenda is required'),
    name: Yup.string().required('Consultant Name is required'),
    date: Yup.string().required('Date is required'),
    time: Yup.string().required('Time is required'),
  });

  // Functions For Add/Edit Appointment
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      // Payload Data
      let formData = {
        user_id: params?.id,
        consultant_name: values.name,
        agenda: values.agenda,
        date: values.date,
        time: values.time,
        status: values?.status,
      };
      // Add Appointment
      if (!appointment) {
        let response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/appointment-create`,
          formData,
          config(token)
        );
        toast.success(response?.data?.message);
      }
      // Edit Appointment
      else {
        let response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/appointment-update/${id}`,
          formData,
          config(token)
        );
        toast.success(response?.data?.message);
      }
      props.onConfirm();
      renderStudentDeatils(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  // find Single Appointment data based on appointment id (Edit Appointment case)
  useEffect(() => {
    if (params?.id) {
      setAppointment(appointments?.find((data) => data?.id === id));
    }
  }, [params?.id]);

  // set user and status values on edit appointment case
  useEffect(() => {
    if (params?.id) {
      setAppointmentStatus({
        label: appointment?.status == 1 ? 'Approved' : 'Pending',
        value: appointment?.status == 1 ? 1 : 0,
      });
    }
  }, [appointment]);

  return (
    <div>
      <div className={classes.backdrop}>
        <div className={`${classes.modal} ${classes.card}`} ref={clickRef}>
          {/* // Detect Click Outside the Modal */}
          <DetectClickOutside clickRef={clickRef} onConfirm={props.onConfirm} />
          <header
            className={`${classes.header} flex items-center justify-between `}
          >
            <h3 className=" text-[16px] lg:text-[24px] font-figtree leading-8 font-medium text-white ">
              {appointment ? 'Edit Appointment' : ' Add Appointment'}
            </h3>
            <RiCloseCircleFill
              size={25}
              className="text-white cursor-pointer"
              onClick={props.onConfirm}
            />
          </header>
          <div
            className={`${classes.content} h-[500px] overflow-y-auto md:overflow-hidden md:h-full`}
          >
            <div className="bg-white transition rounded-[12px] w-full flex  flex-col lg:flex-row  ">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={errorSchema}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form className="w-full">
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 md:gap-2">
                      <ProfileField
                        type="text"
                        label="User Name"
                        name="user"
                        disabled
                        value={userName}
                        placeholder="user name"
                      />

                      <SelectTextField
                        label="Select Status"
                        name="status"
                        value={appointmentStatus}
                        options={statusOptions}
                        onChange={(e) => {
                          props.setFieldValue('status', e.value);
                          setAppointmentStatus(e);
                        }}
                      />

                      <ProfileField
                        type="text"
                        label="Consultant Name"
                        name="name"
                        placeholder="your consultant name"
                      />
                      <ProfileField
                        type="text"
                        label="Agenda"
                        name="agenda"
                        placeholder="your agenda"
                      />
                      <ProfileField
                        type="date"
                        label="Date"
                        name="date"
                        placeholder="appointment date"
                      />
                      <ProfileField
                        type="time"
                        label="Time"
                        name="time"
                        placeholder="appointment time"
                      />
                    </div>
                    <button className="relative  mx-auto  mt-3 w-full xl:w-[230px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative   items-center justify-center block  text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group">
                      <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                      <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                        {loading ? (
                          <Spinner />
                        ) : appointment ? (
                          'Update Appointment'
                        ) : (
                          ' Add Appointment'
                        )}
                      </span>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AddAppointmentFromStudentDetails = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheAddAppointmentFromStudentDetails
          onConfirm={props.onConfirm}
          id={props?.id}
          userName={props?.userName}
          renderStudentDeatils={props?.renderStudentDeatils}
          appointments={props?.appointments}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
