import { Fragment, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

import classes from './Modal.module.css';
import DetectClickOutside from 'modules/DetectClickOutside';

export const TheDocsViewModal = (props) => {
  const clickRef = useRef('');
  const docs = [
    {
      uri: props?.documentData?.url,
      fileType: props?.documentData?.doc_type,
      fileName: props?.documentData?.doc_name,
    }, // Remote file
  ];

  return (
    <div>
      <div className={classes.backdrop}>
        {/* // Detect Click Outside the Modal */}
        <DetectClickOutside clickRef={clickRef} onConfirm={props.onConfirm} />
        <div
          className={`${classes.modal} ${classes.card} h-[95%]`}
          ref={clickRef}
        >
          <header
            className={`${classes.header} flex items-center justify-between `}
          >
            <h3 className=" text-[16px] lg:text-[24px] font-figtree leading-8 font-medium text-white ">
              {props?.documentData?.doc_name}
            </h3>
            <RiCloseCircleFill
              size={25}
              className="text-white cursor-pointer"
              onClick={props.onConfirm}
            />
          </header>
          <div
            className={`h-[500px] overflow-y-auto md:overflow-y-hidden md:h-full`}
          >
            <DocViewer
              documents={docs}
              pluginRenderers={DocViewerRenderers}
              theme={{
                primary: '#00a58e',
                secondary: '#00a58e',
                tertiary: '#fff',
                textSecondary: '#5296d8',
                textTertiary: '#00000099',
                disableThemeScrollbar: false,
              }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
                csvDelimiter: ',', // "," as default,
                pdfZoom: {
                  defaultZoom: 1, // 1 as default,
                  zoomJump: 0.2, // 0.1 as default,
                },
                pdfVerticalScrollByDefault: true, // false as default
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const DocsViewModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheDocsViewModal
          onConfirm={props.onConfirm}
          documentData={props.documentData}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
