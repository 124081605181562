import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import UserImg from 'assets/images/user.png';

const ProfileCard = () => {
  const navigate = useNavigate();

  const profileUrl = useSelector(
    (state) => state?.user?.user_info?.userInfo[0]?.url
  );
  const profileImg = useSelector(
    (state) => state?.user?.user_info?.userInfo[0]?.profile_picture
  );
  const userName = useSelector(
    (state) => state?.user?.user_info?.user[0]?.name
  );

  return (
    <div className="transition px-6 py-6 flex flex-col  bg-white w-full  lg:h-[350px] 2xl:w-[376px] rounded-xl">
      <div className="flex items-center">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6ZM8 3C8 3.53043 7.78929 4.03914 7.41421 4.41421C7.03914 4.78929 6.53043 5 6 5C5.46957 5 4.96086 4.78929 4.58579 4.41421C4.21071 4.03914 4 3.53043 4 3C4 2.46957 4.21071 1.96086 4.58579 1.58579C4.96086 1.21071 5.46957 1 6 1C6.53043 1 7.03914 1.21071 7.41421 1.58579C7.78929 1.96086 8 2.46957 8 3ZM12 11C12 12 11 12 11 12H1C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11ZM11 10.996C10.999 10.75 10.846 10.01 10.168 9.332C9.516 8.68 8.289 8 6 8C3.71 8 2.484 8.68 1.832 9.332C1.154 10.01 1.002 10.75 1 10.996H11Z"
            fill="url(#paint0_linear_2633_8391)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2633_8391"
              x1="-1.78814e-07"
              y1="12"
              x2="13.5725"
              y2="9.82839"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00A58E" />
              <stop offset="1" stopColor="#0172CB" />
            </linearGradient>
          </defs>
        </svg>
        <h5 className="text-blueDacker text-[16px] font-medium font-figtree leading-6 ml-2">
          Profile
        </h5>
      </div>
      <div className="flex items-center justify-center   flex-col 2xl:flex-row transition">
        <div className="flex flex-col justify-center items-center">
          <img
            src={profileImg ? profileUrl : UserImg}
            alt="profile-img"
            loading="lazy"
            className="h-[120px] w-[120px] rounded-full mb-2"
          />
          <h3 className="text-black capitalize text-[21px] leading-7 font-figtree font-medium text-center">
            {userName}
          </h3>
          <div className="flex items-center justify-center mt-4 ">
            <button
              className="h-[44px] w-[123px] rounded-[3px] bg-lightgray text-dark text-[14px] leading-5 font-figtree font-medium"
              onClick={() => navigate('/profile')}
            >
              Update Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
