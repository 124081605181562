import React, { useState } from 'react';
import moment from 'moment';

import { DocsViewModal } from 'components/modal/DocsViewModal';
import { RequiredDocsViewModal } from 'components/modal/RequiredDocsViewModal';

const ApplicationTable = ({ applicationsData }) => {
  const [showMissingDocsModal, setShowMissingDocsModal] = useState(false);
  const [missingDocs, setMissingDocs] = useState(null);
  const [viewOffterModal, setViewOffterLetterModal] = useState(false);
  const [offterLetter, setOffterLetter] = useState({
    url: '',
    doc_type: '',
    doc_name: '',
  });

  // Handler on Cofirm Modal Close
  const handleConfirm = () => {
    setShowMissingDocsModal(false);
    setViewOffterLetterModal(false);
  };

  // Handler For View Offter Lettter
  const handleViewOfferLetter = (data) => {
    setOffterLetter({
      url: data?.url,
      doc_name: data?.offer_letter,
      doc_type: data?.offer_letter?.split('.')[1],
    });
    if (offterLetter.doc_name) {
      setViewOffterLetterModal(true);
    }
  };

  return (
    <div className="flex flex-col px-2 w-full ">
      {/* Required Documets Modal */}
      {showMissingDocsModal && (
        <RequiredDocsViewModal
          onConfirm={handleConfirm}
          documents={missingDocs}
        />
      )}
      {/* View Offter Letter  Modal */}
      {viewOffterModal && (
        <DocsViewModal documentData={offterLetter} onConfirm={handleConfirm} />
      )}
      <div className="sm:-mx-6 lg:-mx-8">
        <div className=" min-w-full py-2  lg:px-4">
          <div className="overflow-x-auto custom-scroll-bar">
            <table className="min-w-full  text-left text-sm font-light">
              <thead className="whitespace-nowrap	bg-successLight font-medium ">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    University Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-success  text-[14px] font-figtree font-bold leading-5"
                  >
                    Applied for Course
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-success  text-[14px] font-figtree font-bold leading-5"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-center text-success  text-[14px] font-figtree font-bold leading-5"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-success text-center text-[14px] font-figtree font-bold leading-5"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {applicationsData.map((data, index) => (
                  <tr className="border-b border-[#E2E2EA]" key={index}>
                    <td className="whitespace-nowrap capitalize px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data.applied_university}
                    </td>
                    <td className="whitespace-nowrap px-4 capitalize py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.course_name}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {moment(data?.created_at).format('MMMM DD, YYYY')}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.status == 1 && (
                        <p className="bg-successLight capitalize text-success text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          processing
                        </p>
                      )}
                      {data?.status == 0 && (
                        <p className="bg-red-100 text-red-600 capitalize text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          pending
                        </p>
                      )}
                      {data?.status == 2 && (
                        <p className="bg-pending text-white capitalize text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Rejetcted
                        </p>
                      )}
                      {data?.status == 'Admission Offered' && (
                        <p className="bg-success text-white capitalize text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Admission Offered
                        </p>
                      )}
                    </td>
                    <td className="whitespace-nowrap flex justify-center items-center px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.status !== 'Admission Offered' && (
                        <button
                          className=" block mx-auto text-[12px] w-[150px] h-[30px] md:h-[30px] bg-pending rounded-[3px] text-white  font-figtree font-medium text-center disabled:opacity-25"
                          onClick={() => (
                            setShowMissingDocsModal(true),
                            setMissingDocs({
                              missing_docs: data?.missing_documents,
                              required_docs: data?.required_doc,
                            })
                          )}
                          disabled={data?.status == 1}
                        >
                          Required Documents
                        </button>
                      )}
                      {data?.status == 'Admission Offered' && (
                        <button
                          onClick={() => handleViewOfferLetter(data)}
                          className=" block mx-auto text-[12px] w-[150px] h-[30px] md:h-[30px] bg-success rounded-[3px] text-white  font-figtree font-medium text-center "
                        >
                          View Offer Lettter
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationTable;
