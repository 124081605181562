import axios from 'axios';
import { toast } from 'react-toastify';

import * as ActionTypes from 'redux/actions/ActionTypes/ActionTypes';
import { setLoadingFalse, setLoadingTrue } from './LoadingActions';
import { config } from 'helpers/config/Config';

let backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
let defaultSearchQuery = '';

// Get All Students Paginated
export const getAllStudents = (page, search, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    const response = await axios.get(
      `${backendUrl}/admin/student-list?page=${page}&search=${search}`,
      config(token)
    );
    dispatch({
      type: ActionTypes.GET_ALL_STUDENTS,
      payload: response?.data,
    });
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};
// Get All Students List Without Pagination
export const getAllStudentsList = (token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    const response = await axios.get(
      `${backendUrl}/admin/all-student-list`,
      config(token)
    );
    dispatch({
      type: ActionTypes.GET_ALL_STUDENTS_DATA,
      payload: response?.data,
    });
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Add New Student
export const adminAddStudent = (formData, page, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.post(
      `${backendUrl}/admin/create-user`,
      formData,
      config(token)
    );
    dispatch(setLoadingFalse());
    dispatch(getAllStudents(page, defaultSearchQuery, token));
    toast.success(response?.data?.message);
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Admin Get Paginated Appointments
export const admingetAppointments =
  (page, search, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.get(
        `${backendUrl}/admin/appointment-list?page=${page}&search=${search}`,
        config(token)
      );
      dispatch({
        type: ActionTypes.ADMIN_GET_ALL_STUDENTS_APPOINTMENTS,
        payload: response?.data,
      });
      dispatch(setLoadingFalse());
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error.message);
    }
  };

// Admin Get All Users Appointments
export const admingetAllAppointments = (token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.get(
      `${backendUrl}/admin/all-appointment-list`,
      config(token)
    );
    dispatch({
      type: ActionTypes.GET_ALL_APPOINTMENTS_DATA,
      payload: response?.data,
    });
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error.message);
  }
};

// Add Appointment
export const adminAddAppointment =
  (formData, page, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.post(
        `${backendUrl}/admin/appointment-create`,
        formData,
        config(token)
      );
      dispatch(setLoadingFalse());
      dispatch(admingetAppointments(page, defaultSearchQuery, token));
      toast.success(response?.data?.message);
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
// Update Appointment
export const adminEditAppointment =
  (formData, id, page, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.post(
        `${backendUrl}/admin/appointment-update/${id}`,
        formData,
        config(token)
      );
      dispatch(setLoadingFalse());
      dispatch(admingetAppointments(page, defaultSearchQuery, token));
      toast.success(response?.data?.message);
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

// Delete User Appointment
export const adminDeleteUserAppointment =
  (id, page, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.delete(
        `${backendUrl}/admin/appointment-cancel/${id}`,
        config(token)
      );
      dispatch(admingetAppointments(page, defaultSearchQuery, token));
      dispatch(setLoadingFalse());
      toast.success(response?.data?.original?.message);
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

//Admin Delete User
export const adminDeleteUser = (id, page, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.delete(
      `${backendUrl}/admin/delete/${id}`,
      config(token)
    );
    dispatch(getAllStudents(page, defaultSearchQuery, token));
    dispatch(setLoadingFalse());
    toast.success(response?.data?.original?.message);
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

//Admin Approved Appointment
export const adminApprovedAppointmentStatus =
  (id, page, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.post(
        `${backendUrl}/admin/appointment-comfirm/${id}`,
        '',
        config(token)
      );
      dispatch(admingetAppointments(page, defaultSearchQuery, token));
      dispatch(setLoadingFalse());
      toast.success(response?.data?.original?.message);
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

// Admin Create Zoom Meeting
export const adminCreateMeeting = (id, page, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.get(
      `${backendUrl}/create-meeting/${id}`,
      config(token)
    );
    dispatch(admingetAppointments(page, defaultSearchQuery, token));
    dispatch(setLoadingFalse());
    toast.success(response?.data);
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};
// Admin Launch Zoom Meeting
export const adminLaunchMeeting = (id, page, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.get(
      `${backendUrl}/lanchMeeting/${id}`,
      config(token)
    );
    // dispatch(admingetAppointments(page, token));
    dispatch(setLoadingFalse());
    if (response?.data) {
      window.open(response?.data);
    }
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Admin Launch Zoom Meeting
export const adminGetAnalytics = (token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.get(
      `${backendUrl}/admin/progress-bar`,
      config(token)
    );
    dispatch({
      type: ActionTypes.ADMIN_GET_ANALYTICS,
      payload: response?.data,
    });
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Admin Create Applications
export const adminCreateApplication =
  (formData, page, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.post(
        `${backendUrl}/admin/apply-for-admission`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            Authorization: `Bearer ${token}`,
          },
        },
        config(token)
      );
      toast.success(response?.data?.original?.message);
      dispatch(adminGetApplications(page, defaultSearchQuery, token));
      dispatch(setLoadingFalse());
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

// Admin Get  Applications
export const adminGetApplications =
  (page, search, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.get(
        `${backendUrl}/admin/get-admission-details?page=${page}&search=${search}`,
        config(token)
      );
      dispatch({
        type: ActionTypes.FILTER_APPLICATIONS,
        payload: response?.data,
      });
      dispatch(setLoadingFalse());
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

// Admin Delete Application
export const adminDeleteUserApplication =
  (id, page, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.delete(
        `${backendUrl}/admin/deleteAppication/${id}`,
        config(token)
      );
      dispatch(adminGetApplications(page, defaultSearchQuery, token));
      dispatch(setLoadingFalse());
      toast.success(response?.data?.original?.message);
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

// Update Application
export const adminUpdateApplication =
  (formData, page, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.post(
        `${backendUrl}/admin/update-admission`,
        formData,
        config(token)
      );
      dispatch(setLoadingFalse());
      dispatch(adminGetApplications(page, defaultSearchQuery, token));
      toast.success(response?.data?.original?.message);
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
