import React, { useState } from 'react';

import OfferIcon from 'assets/images/offer-icon.png';
import NoRecord from 'components/norecord/NoRecord';
import { DocsViewModal } from 'components/modal/DocsViewModal';

const OffersCrad = ({ latestOffersData }) => {
  const [viewOfferLetter, setViewOfferLetter] = useState(false);
  const [offerLetter, setOfferLetter] = useState({
    url: '',
    doc_type: '',
    doc_name: '',
  });

  const handleConfirmModal = () => {
    setViewOfferLetter(false);
  };
  return (
    <div className="transition px-4 py-6 flex flex-col justify-start bg-white w-full h-[400px] lg:h-[350px] 2xl:w-[376px] rounded-xl">
      {/* View Offter Letter  Modal */}
      {viewOfferLetter && (
        <DocsViewModal
          documentData={offerLetter}
          onConfirm={handleConfirmModal}
        />
      )}
      <div className="flex items-center">
        <img loading="lazy" src={OfferIcon} alt="icon" />
        <h5 className="text-blueDacker text-[16px] font-medium font-figtree leading-6 ml-2">
          Latest Offers
        </h5>
      </div>
      {latestOffersData?.length ? (
        <div className="overflow-y-scroll custom-scroll-bar overflow-x-hidden pt-4">
          <div className="overflow-x-auto min-w-full">
            {latestOffersData.map((data, index) => (
              <div
                className="flex py-3 items-center justify-between border-b border-lightgray"
                key={index}
              >
                <p className="text-[14px] font-figtree font-normal leading-5 text-dark">
                  Offer letter from {data?.applied_university}
                </p>
                <span
                  className="bg-primary rounded-full cursor-pointer px-2 py-2 text-[10px] font-figtree text-white font-medium"
                  onClick={() => (
                    setViewOfferLetter(true),
                    setOfferLetter({
                      url: data?.url,
                      doc_name: data?.offer_letter,
                      doc_type: data?.offer_letter?.split('.')[1],
                    })
                  )}
                >
                  View
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-full ">
          <NoRecord title={'No Offer Letters Found'} />
        </div>
      )}
    </div>
  );
};

export default OffersCrad;
