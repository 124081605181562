import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import ProfileField from "components/textfield/ProfileField";
import { addAppointment } from "redux/actions/ActionCreators/UserActions";
import Spinner from "components/spinner/Spinner";
import SelectTextField from "components/textfield/SelectField";
import {
  adminAddAppointment,
  adminEditAppointment,
} from "redux/actions/ActionCreators/AdminActions";

const AppointmentForm = ({ id, onConfirm, page }) => {
  const dispatch = useDispatch();

  const appointments = useSelector((state) => state?.admin?.appointments);
  const token = useSelector((state) => state.auth.user.access_token);
  const role = useSelector((state) => state?.auth?.user?.role);
  const loading = useSelector((state) => state.user.loading);
  const usersData = useSelector((state) => state?.admin?.allStudents);
  const isAdmin = role === "admin" ? true : false;

  const [appointmentData, setAppointmentData] = useState(null);
  const [selectUser, setSelectUser] = useState({
    label: "Select User",
    value: "",
  });
  const [appointmentStatus, setAppointmentStatus] = useState({
    label: "Select Status",
    value: "",
  });

  // Status Options List
  const statusOptions = [
    {
      value: 0,
      label: "Pending",
    },
    {
      value: 1,
      label: "Approved",
    },
  ];

  // User Options List
  const options =
    isAdmin && usersData.length
      ? usersData.map((data) => {
          return {
            value: data.id,
            label: `${data.name} (${data?.email})`,
          };
        })
      : [];

  // Form Initial Values
  let initialValues = {
    status: appointmentData?.status,
    user: appointmentData?.user?.id,
    name: appointmentData?.consultant_name,
    agenda: appointmentData?.agenda,
    date: appointmentData?.date,
    time: appointmentData?.time
      ? moment(appointmentData?.time, "HH:mm:ss").format("hh:mm")
      : "",
  };

  // Error Schema
  const errorSchema = Yup.object().shape({
    user: isAdmin ? Yup.string().required("User is required") : Yup.string(),
    agenda: Yup.string().required("Agenda is required"),
    name: Yup.string().required("Consultant Name is required"),
    date: Yup.string().required("Date is required"),
    time: Yup.string().required("Time is required"),
  });

  // Form Submission
  const handleSubmit = (values, { resetForm }) => {
    // Payload Data
    let data = {
      user_id: values.user,
      consultant_name: values.name,
      agenda: values.agenda,
      date: values.date,
      time: values.time,
      status: values?.status,
    };
    //Set Current Page
    const currentPage =
      appointments?.data?.length === 1 && page > 1 ? page + 1 : page;
    // If Role Is Admin
    if (isAdmin) {
      // If !id it will call add appointment otherwise edit appointment
      if (!id) {
        dispatch(adminAddAppointment(data, page, token));
      } else {
        dispatch(adminEditAppointment(data, id, currentPage, token));
      }
    }
    // If Role Is User
    else {
      dispatch(addAppointment(data, page, token));
    }
    onConfirm();
  };

  // find Single Appointment data based on appointment id (Edit Appointment case)
  useEffect(() => {
    if (id) {
      setAppointmentData(appointments?.data?.find((data) => data?.id === id));
    }
  }, [id]);

  // set user and status values on edit appointment case
  useEffect(() => {
    if (id) {
      setSelectUser({
        label: appointmentData?.user?.name,
        value: appointmentData?.user?.name,
      });
      setAppointmentStatus({
        label: appointmentData?.status == 1 ? "Approved" : "Pending",
        value: appointmentData?.status == 1 ? 1 : 0,
      });
    }
  }, [appointmentData]);

  return (
    <div className="bg-white transition rounded-[12px] w-full flex  flex-col lg:flex-row  ">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={errorSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form className="w-full">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 md:gap-2">
              {isAdmin && (
                <SelectTextField
                  label="Select User"
                  name="user"
                  value={selectUser}
                  options={options}
                  onChange={(e) => {
                    props.setFieldValue("user", e.value);
                    setSelectUser(e);
                  }}
                />
              )}
              {isAdmin && (
                <SelectTextField
                  label="Select Status"
                  name="status"
                  value={appointmentStatus}
                  options={statusOptions}
                  onChange={(e) => {
                    props.setFieldValue("status", e.value);
                    setAppointmentStatus(e);
                  }}
                />
              )}

              <ProfileField
                type="text"
                label="Consultant Name"
                name="name"
                placeholder="Your consultant name"
              />
              <ProfileField
                type="text"
                label="Agenda"
                name="agenda"
                placeholder="Your agenda"
              />
              <ProfileField
                type="date"
                label="Date"
                name="date"
                placeholder="appointment date"
              />
              <ProfileField
                type="time"
                label="Time"
                name="time"
                placeholder="appointment time"
              />
            </div>
            <button className="relative  mx-auto  mt-3 w-full xl:w-[230px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative   items-center justify-center block  text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group">
              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
              <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                {loading ? (
                  <Spinner />
                ) : id ? (
                  "Update Appointment"
                ) : (
                  "Add Appointment"
                )}
              </span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AppointmentForm;
