import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import ProfileIcon from 'assets/images/profile-icon.png';
import ProfileField from 'components/textfield/ProfileField';
import { useDispatch, useSelector } from 'react-redux';
import { addUserInfo } from 'redux/actions/ActionCreators/UserActions';
import Spinner from 'components/spinner/Spinner';
import SelectTextField from 'components/textfield/SelectField';

const ProfileForm = ({ profileImage }) => {
  const dispatch = useDispatch();

  const user_info = useSelector((state) => state?.user?.user_info?.userInfo[0]);
  const user = useSelector((state) => state?.user?.user_info?.user[0]);
  const token = useSelector((state) => state?.auth?.user.access_token);
  const loading = useSelector((state) => state?.user?.loading);

  const [religion, setReligion] = useState({
    label: 'Religion',
    value: '',
  });

  // Religoin Options List
  const optionsList = [
    {
      label: 'Islam',
      value: 'islam',
    },
    {
      label: 'Christian',
      value: 'christian',
    },
    {
      label: 'Hindu',
      value: 'hindu',
    },
  ];

  // Initial Form Values
  const [initialValues, setInitailValues] = useState({
    fullName: user?.name,
    fatherName: user_info?.father_name,
    email: user?.email,
    dob: user_info?.date_of_birth,
    address: user_info?.home_address,
    cnic: user_info?.nic,
    cnicExpiry: user_info?.nic_expire_date,
    passportNum: user_info?.passport_no,
    passportExpiry: user_info?.passport_expire_date,
    religion: user_info?.religion,
  });

  // Error Schema
  const errorSchema = Yup.object().shape({
    fullName: Yup.string().required('Name is required'),
    fatherName: Yup.string().required('Father Name is required'),
    email: Yup.string().email().required('Email is required'),
    dob: Yup.string().required('Date Of Birth is required'),
    address: Yup.string().required('Birth Place is required'),
    cnic: Yup.string().required('NIC is required'),
    cnicExpiry: Yup.string().required('NIC expiry is required'),
    passportNum: Yup.string().required('Passport Number is required'),
    passportExpiry: Yup.string().required('Passport Expiry is required'),
    religion: Yup.string().required('Religion is required'),
  });

  // Form Submission
  const handleSubmit = (values, { resetForm }) => {
    let formData = new FormData();
    formData.append('name', values.fullName);
    formData.append('father_name', values.fatherName);
    formData.append('email', values.email);
    formData.append('date_of_birth', values.dob);
    formData.append('home_address', values.address);
    formData.append('nic', values.cnic);
    formData.append('nic_expire_date', values.cnicExpiry);
    formData.append('passport_no', values.passportNum);
    formData.append('passport_expire_date', values.passportExpiry);
    formData.append('religion', religion?.value);
    {
      profileImage && formData.append('profile_picture', profileImage);
    }
    dispatch(addUserInfo(formData, token));
  };

  // Function To Handle Change Events
  const handleChange = (e) => {
    const value = e.target.value;
    setInitailValues({
      ...initialValues,
      [e.target.name]: value,
    });
  };

  // Set Religion value
  useEffect(() => {
    if (user_info?.religion) {
      setReligion({
        label: user_info?.religion,
        value: user_info?.religion,
      });
    }
  }, []);

  return (
    <div>
      <div className="flex transition items-center">
        <img
          src={ProfileIcon}
          loading="lazy"
          alt="icon"
          className="w-[12px] h-[12px] mr-3"
        />
        <h5 className="text-[16px] text-blueDacker leading-6 font-figtree font-medium">
          Personal Details
        </h5>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={errorSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form className="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
            <ProfileField
              type="text"
              label="Full Name"
              name="fullName"
              placeholder="your name"
              value={initialValues.fullName}
              onChange={handleChange}
            />
            <ProfileField
              type="text"
              label="Father Name"
              name="fatherName"
              placeholder="your father name"
              value={initialValues.fatherName}
              onChange={handleChange}
            />
            <ProfileField
              type="email"
              label="Email"
              name="email"
              readOnly
              placeholder="your email"
              value={initialValues.email}
              onChange={handleChange}
            />
            <ProfileField
              type="date"
              label="Date Of Birth"
              name="dob"
              placeholder="your date of birth"
              value={initialValues.dob}
              onChange={handleChange}
            />
            <ProfileField
              type="text"
              label="Home Address"
              name="address"
              placeholder="your address"
              value={initialValues.address}
              onChange={handleChange}
            />
            <ProfileField
              type="text"
              label="NIC Number (I.D Card)"
              name="cnic"
              placeholder="your nic number"
              value={initialValues.cnic}
              onChange={handleChange}
            />
            <ProfileField
              type="date"
              label="NIC Expiry Date"
              name="cnicExpiry"
              placeholder="your nic date of expiry"
              value={initialValues.cnicExpiry}
              onChange={handleChange}
            />
            <ProfileField
              type="text"
              label="Passport Number"
              name="passportNum"
              placeholder="your passport numnber"
              value={initialValues.passportNum}
              onChange={handleChange}
            />
            <ProfileField
              type="date"
              label="Passport Expiry Date"
              name="passportExpiry"
              placeholder="your passport expiry date"
              value={initialValues.passportExpiry}
              onChange={handleChange}
            />
            <SelectTextField
              label="Religion"
              name="religion"
              value={religion}
              options={optionsList}
              onChange={(e) => {
                props.setFieldValue('religion', e.value);
                setReligion(e);
              }}
            />

            <button className="relative mx-auto lg:mx-0 mb-3 w-[230px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start block text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group">
              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
              <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                {loading ? <Spinner /> : 'Update Profile'}
              </span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileForm;
